import Html from 'Component/Html';
import {
    CmsBlockComponent as SourceCmsBlock,
} from 'SourceComponent/CmsBlock/CmsBlock.component';

/** @namespace Theme/Component/CmsBlock/Component */
export class CmsBlockComponent extends SourceCmsBlock {
    renderFooterCmsBlock() {
        const {
            cmsBlock: {
                content,
                disabled,
            },
            blockType,
        } = this.props;

        if (disabled) {
            return null;
        }

        return (
            <div
              block="CmsBlock"
              elem="Wrapper"
              mods={ { type: blockType } }
            >
                <Html content={ content.replaceAll('{contactUsUrl}', window.contactUsUrl || '/contact-us') } />
            </div>
        );
    }

    render() {
        const { footer_content: { footer_cms } = {} } = window.contentConfiguration;

        const {
            cmsBlock: {
                identifier,
            },
        } = this.props;

        if (identifier === footer_cms) {
            return this.renderFooterCmsBlock();
        }

        return super.render();
    }
}

export default CmsBlockComponent;
