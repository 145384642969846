/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */

import { AccountPageUrl } from 'Route/MyAccount/MyAccount.config';
import {
    FooterComponent as SourceFooter,
} from 'SourceComponent/Footer/Footer.component';
import { isSignedIn } from 'Util/Auth/IsSignedIn';
import { scrollToTop } from 'Util/Browser';
import history from 'Util/History';
import sso from 'Util/SSO';
import { appendWithStoreCode } from 'Util/Url';

/** @namespace Theme/Component/Footer/Component */
export class FooterComponent extends SourceFooter {
    componentDidMount() {
        const { doLogout } = this.props;

        this.loginLinkClickHandler = (event) => {
            if (event.target.classList.contains('loginLink')) {
                event.preventDefault();
                event.stopPropagation();

                if (!isSignedIn() && sso.isEnabled()) {
                    sso.gotoLogin({ target: 'footer link' });
                } else {
                    history.push({ pathname: appendWithStoreCode(isSignedIn() ? '/' : AccountPageUrl.LOGIN_URL) });
                    scrollToTop();
                }

                if (isSignedIn()) {
                    doLogout();
                }
            }
        };

        document.body.addEventListener('click', this.loginLinkClickHandler);
    }

    componentWillUnmount() {
        document.body.removeEventListener('click', this.loginLinkClickHandler);
    }

    renderNewsletterSubscriptionBlock() {
        return null;
    }

    renderColumns() {
        return null;
    }

    renderCopyrightContent() {
        return null;
    }

    render() {
        return (
            <footer block="Footer" aria-label="Footer">
                { this.renderContent() }
                { this.renderCopyrightContent() }
            </footer>
        );
    }
}

export default FooterComponent;
