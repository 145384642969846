import { connect } from 'react-redux';

import {
    FooterContainer as SourceFooterContainer,
    mapStateToProps,
} from 'SourceComponent/Footer/Footer.container';

export const MyAccountDispatcher = import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
);

/** @namespace Theme/Component/Footer/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, false, dispatch),
    ),
});

/** @namespace Theme/Component/Footer/Container */
export class FooterContainer extends SourceFooterContainer {
    containerFunctions = {
        onItemClick: this.onItemClick.bind(this),
        doLogout: this.doLogout.bind(this),
    };

    doLogout() {
        const { logout } = this.props;
        logout();
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FooterContainer);
